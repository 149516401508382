import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import IconLinkArrowSvg from '../images/icons/link-arrow.svg'
import { BREAKPOINTS, ANIMATION } from '../styles/cssVariables'
import { calculateDaysBetween } from '../utils/utils'
import Layout from '../components/layout/layout'
import Container from '../components/layout/container'
import SEO from '../components/layout/seo'

const Topmatter = styled.section`
  @media (min-width: ${BREAKPOINTS.tblUp}px) {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`

const ImgContainer = styled.div`
  ${(props) =>
    !props.hasImage &&
    `
    padding-bottom: calc(1 / 3 * 100%);
    background-color: var(--color-skobeloff);
  `}
`

const MetaContainer = styled(Container)`
  @media (min-width: ${BREAKPOINTS.tblUp}px) {
    position: absolute;
    top: 50%;
    left: 50%;

    color: #fff;
    text-align: center;

    transform: translate(-50%, -50%);
    z-index: 1;

    h1,
    span {
      color: #fff;
    }

    h1 {
      margin-bottom: 2rem;
    }
  }

  @media (max-width: ${BREAKPOINTS.tbl}px) {
    h1 {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
`

const Content = styled.article`
  padding-top: 72px;
  padding-bottom: calc(50px + 90px);
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  color: var(--black);
  width: 60%;
  @media (max-width: 1700px) {
    width: 70%;
  }
  @media (max-width: 1500px) {
    width: 75%;
  }
  @media (max-width: 1300px) {
    width: 80%;
  }
  @media (max-width: 1150px) {
    width: 90%;
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    width: 100%;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding-top: 30px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }

  p:not(:last-child) {
    margin-bottom: 1rem;
  }
  p {
    color: var(--color-body);
    a {
      text-decoration: underline;
    }
    a:hover {
      color: var(--color-heading) !important;
    }
  }
  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  li:last-child {
    margin-bottom: 1rem;
  }

  img {
    max-width: 100%;
  }
`

const InlineAnchor = styled.a`
  color: var(--color-skobeloff) !important;
  transition: color ${ANIMATION.speedMedium} ease-in-out,
   
  font-weight: bold !important;

  u {
    font-weight: bold !important; 
  }

  &:hover {
    color: var(--color-heading) !important;
    text-decoration: none;
  }

  u:hover {
    color: var(--color-heading) !important;
    text-decoration: none;
  }
  
`

const ButtonDownload = styled.a`
width: fit-content;
position: relative;
display: flex;
align-items: center;
box-sizing: border-box;
border: 1px solid var(--color-skobeloff);
cursor: pointer;
padding: 1.25em 2.25em;
border-radius: 4px;
margin-top: 50px;
margin-bottom: 50px;
background-color: var(--color-skobeloff);
color: #fff !important;
margin-left: auto;
margin-right: auto;
transition: color ${ANIMATION.speedMedium} ease,
background-color ${ANIMATION.speedMedium} ease;

&:hover {
  background-color: #fff;
  color: var(--color-skobeloff) !important;

}

@media (max-width: ${BREAKPOINTS.lrg}px) {
  padding: 1em 1.5em;
}

`

const Arrow = styled(IconLinkArrowSvg)`
  margin-left: 0.4em;    
  transform: rotate(90deg);

`


class GuidePageTemplate extends React.Component {
  render() {
    const content = get(this.props, 'data.contentfulGuideContent')


    const options = {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
        [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
        [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
        [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
        [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
        [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
        [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
        [INLINES.HYPERLINK]: (node, children) => (
          <InlineAnchor href={node.data.uri}>{children}</InlineAnchor>
        ),
        [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
          <GatsbyImage
            alt={node.data.target.title}
            image={node.data.target.gatsbyImageData}
          />
        ),
      },
    }

    return (
      <Layout location={this.props.location}>
        {/* <SEO pageTitle={`${content.title}`} ogImage={content?.image?.gatsbyImageData?.images?.fallback?.src} /> */}
        <SEO pageTitle={`${content.title}`} />
        <Topmatter>
          <ImgContainer hasImage={Boolean(content.image)}>
            {content.image && (
              <GatsbyImage
                alt={content.image.title}
                image={content.image.gatsbyImageData}
              />
            )}
          </ImgContainer>

          <MetaContainer width={12}>
            <h1>{content.title}</h1>
       
            
          </MetaContainer>
        </Topmatter>

        <Content>
          <Container width={12}>
            {renderRichText(content.mainContent, options)}

            {content.attachedFile && (
              <ButtonDownload     href={content.attachedFile.file.url}
              rel="nofollow"
              target="__blank">
  
                  Download
                <Arrow />
              </ButtonDownload>
            )}
          </Container>
        </Content>
      </Layout>
    )
  }
}

export default GuidePageTemplate

export const guidePageQuery = graphql`
  query GuideContentBySlug($slug: String!) {
    contentfulGuideContent(slug: { eq: $slug }) {
      title
      slug
      pillar
      publishDate
      image {
        title
        gatsbyImageData(layout: FULL_WIDTH)
      }
      mainContent {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            title
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      attachedFile {
        file {
          url
        }
      }
    }
  }
`
